import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Layout } from '../../components/Layout';
import { Main } from '../../docs/Blog';
import { SEO } from '../../components/SEO';
import debounce from '../../lib/debounce';

const BlogIndexPage = ({ data }) => {
  const _site_title = data.site.siteMetadata?.title || 'Title';
  const _posts = data.allMarkdownRemark.nodes;

  let _card_adjust_func;

  useEffect(() => {
    import('../../lib/card').then(setCardsImageHeight => {
      setCardsImageHeight.default();

      _card_adjust_func = debounce(function () {
        setCardsImageHeight.default();
      }, 300);

      window.addEventListener('resize', _card_adjust_func);
    });

    return () => {
      window.removeEventListener('resize', _card_adjust_func);
    };
  }, []);

  return (
    <Layout hasSidebar={true} footer={true} fullPage={false} header={true}>
      <SEO title={_site_title} />
      <Main title="News from If Design System">
        {(!_posts || (_posts && _posts.length === 0)) && <div className="if alert-banner">No _posts found.</div>}
        {_posts && _posts.length !== 0 && (
          <>
            <h2 className="if heading medium">Articles</h2>
            <ol className="if cards articles ids-doc">
              {_posts.map(post => {
                const title = post.frontmatter.title || post.fields.slug;
                const tags = post.frontmatter.tags;
                const author = post.frontmatter.author;
                const link = `/blog${post.fields.slug.replace('//', '/').replace(/\/+$/, '')}`;

                return (
                  <li className="if ids-doc editorial-card" key={post.fields.slug}>
                    <div className="if content">
                      <span className="ids-doc if category">Release</span>
                      <h3 className="if title heading small">
                        <Link to={link} className="if">
                          {title}
                        </Link>
                      </h3>
                      <p
                        className="if ids-doc preview"
                        dangerouslySetInnerHTML={{
                          __html: post.frontmatter.description || post.excerpt
                        }}
                        itemProp="description"
                      />
                      <div className="if meta">
                        {tags && (
                          <div className="ids-doc subtle-tags-holder">
                            {tags
                              .filter(tag => tag.indexOf('@') === -1)
                              .map((tag, index) => {
                                return (
                                  <span className="if ids-doc subtle-tag" key={`key-tag-0${index}`}>
                                    #{tag}
                                  </span>
                                );
                              })}
                          </div>
                        )}

                        <small className="ids-doc if author">{author}</small>
                      </div>
                    </div>
                    <span className="if image lifestyle">
                      <GatsbyImage
                        alt="image related to the post"
                        image={post.frontmatter.teaserImage.childImageSharp.gatsbyImageData}
                        className="if"
                      />
                    </span>
                  </li>
                );
              })}
            </ol>
          </>
        )}
      </Main>
    </Layout>
  );
};

export default BlogIndexPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { fields: { collection: { eq: "blog" } }, frontmatter: { hidden: { ne: true } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          tags
          author
          teaserImage {
            sourceInstanceName
            name
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          date
        }
      }
    }
  }
`;

BlogIndexPage.propTypes = {
  data: PropTypes.shape()
};
